import { Box, Divider } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { colors } from "../../constants/colors";
import { imgs } from "../../constants/images";
import { amplitudeTrack } from "../../utils/amplitude/amplitude";

export default function Footer() {
  const menu = [
    {
      id: 1, name: 'ผ่อนสินค้า',
      link: 'https://www.ufriend.shop/%e0%b8%9c%e0%b9%88%e0%b8%ad%e0%b8%99%e0%b8%aa%e0%b8%b4%e0%b8%99%e0%b8%84%e0%b9%89%e0%b8%b2/',
      key: 'F_Footage_O_lease'
    },
    {
      id: 2, name: 'ออมดาวน์',
      link: 'https://www.ufriend.shop/%e0%b8%ad%e0%b8%ad%e0%b8%a1%e0%b8%94%e0%b8%b2%e0%b8%a7%e0%b8%99%e0%b9%8c/',
      key: 'F_Footage_O_deposit'
    },
    {
      id: 3, name: 'ไอโฟนแลกเงิน',
      link: 'https://www.ufriend.shop/%e0%b9%84%e0%b8%ad%e0%b9%82%e0%b8%9f%e0%b8%99%e0%b9%81%e0%b8%a5%e0%b8%81%e0%b9%80%e0%b8%87%e0%b8%b4%e0%b8%99/',
      key: 'F_Footage_O_refin'
    },
    {
      id: 4, name: 'สินค้าของเรา',
      link: 'https://www.ufriend.shop/shop/',
      key: 'F_Footage_O_ourproduct'
    },
    {
      id: 5, name: 'เกี่ยวกับยูเฟรนด์',
      link: 'https://www.ufriend.shop/%e0%b9%80%e0%b8%81%e0%b8%b5%e0%b9%88%e0%b8%a2%e0%b8%a7%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b8%a2%e0%b8%b9%e0%b9%80%e0%b8%9f%e0%b8%a3%e0%b8%99%e0%b8%94%e0%b9%8c/',
      key: 'F_Footage_O_ufriend'
    },
    {
      id: 6, name: 'บทความ',
      link: 'https://www.ufriend.shop/%e0%b8%9a%e0%b8%97%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1/',
      key: 'F_Footage_O_topic'
    },
    {
      id: 7, name: 'สาขาใกล้คุณ',
      link: '/near-branch',
      key: 'F_Footage_O_branch'
    },
    {
      id: 8, name: 'ร่วมงานกับเรา',
      link: 'https://www.ufriend.shop/%e0%b8%a3%e0%b9%88%e0%b8%a7%e0%b8%a1%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b9%80%e0%b8%a3%e0%b8%b2-new/',
      key: 'F_Footage_O_career'
    },
  ];
  const contactMenu = [
    {
      img: "https://www.ufriend.shop/wp-content/uploads/2023/08/Facebook.png.webp",
      name: "ufriendth",
      link: "https://www.facebook.com/ufriendth",
      key: 'F_Footage_O_fb'
    },
    {
      img: "https://www.ufriend.shop/wp-content/uploads/2023/08/Line.png.webp",
      name: "@ufriend",
      link: "https://page.line.me/945uzjoj?openQrModal=true",
      key: 'F_Footage_O_line'
    },
    {
      img: "https://www.ufriend.shop/wp-content/uploads/2023/08/TikTok.png.webp",
      name: "@ufriendshop",
      link: "https://www.tiktok.com/@ufriendshop",
      key: 'F_Footage_O_tiktok'
    },
    {
      img: "https://www.ufriend.shop/wp-content/uploads/2023/08/Youtube.png.webp",
      name: "@ufriendshop",
      link: "https://www.youtube.com/@ufriendshop",
      key: 'F_Footage_O_youtube'
    },
    {
      img: "https://www.ufriend.shop/wp-content/uploads/2023/08/Phone.png.webp",
      name: "064-616-9699",
      link: "tel:0646169699",
      key: 'F_Footage_O_tel'
    },
  ];
  const appLink = [
    {
      img: imgs.appleStore,
      link: "https://apps.apple.com/th/app/ufriend/id1585122357?l=th",
      key: 'F_Footage_O_appstore'
    },
    {
      img: imgs.playStore,
      link: "https://apps.apple.com/th/app/ufriend/id1585122357?l=th",
      key: 'F_Footage_O_googleplay'
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.themeMainColor,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: { xs: "100%", lg: "70%" },
          padding: "60px 30px",
          gap: { xs: 4, lg: 7 },
        }}
      >
        {/* Logo and Tagline */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            gap: 2,
            minWidth: { xs: "100%", sm: "20%" },
          }}
        >
          <img
            src={imgs.appLogo}
            style={{
              width: "72px",
              height: "72px",
            }}
            alt="logo ufriend"
          />
          <Typography variant="h6" fontWeight={500}>
            <span style={{ color: colors.yellow }}>ยู</span>
            <span style={{ color: colors.white }}>เฟรนด์</span>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ color: colors.white }}
            mb={2}
          >
            ชีวิตง่ายดี เพราะมีเพื่อนที่รู้ใจ
          </Typography>
        </Box>

        {/* App Links */}
        <Box
          sx={{
            display: { md: "none" },
            textAlign: "center",
            gap: 1,
            flexShrink: 0,
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" fontWeight={500} mb={2} color={colors.white}>
            แอป<span style={{ color: colors.yellow }}>ยู</span>เฟรนด์ โหลดเลย!
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexShrink: 0,
              justifyContent: "center",
            }}
          >
            {appLink.map((item, index) => (
              <Link key={index} href={item.link} target="_blank">
                <img
                  src={item.img}
                  style={{ width: "135px", height: "40px" }}
                  alt={`App Link ${index}`}
                  onClick={() => { amplitudeTrack(item.key) }}
                />
              </Link>
            ))}
          </Box>
        </Box>

        {/* Divider (Mobile View) */}
        <Divider
          variant="middle"
          sx={{
            borderBottomWidth: 1,
            borderColor: colors.white,
            display: { md: "none" },
          }}
        />

        {/* Menu and Contact */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: { xs: 3, lg: 10 },
            minWidth: { xs: "100%", sm: "40%" },
          }}
        >
          {/* Menu Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: { xs: "center", md: "left" },
              gap: 2,
            }}
          >
            <Typography
              variant="h6"
              color={colors.white}
              fontWeight={500}
              mb={2}
            >
              เมนู
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", md: "column" },
                justifyContent: "center",
                flexWrap: "wrap",
                maxWidth: "500px",
                margin: "auto",
                gap: { xs: 3, md: 1 },
                fontWeight: 300,
              }}
            >
              {menu.map((item, index) => (
                <Link
                  key={index}
                  color={colors.white}
                  href={item.link}
                  underline="none"
                  onClick={() => { amplitudeTrack(item.key) }}
                >
                  {item.name}
                </Link>
              ))}
            </Box>
          </Box>

          {/* Divider (Mobile View) */}
          <Divider
            variant="middle"
            sx={{
              borderBottomWidth: 1,
              borderColor: colors.white,
              display: { md: "none" },
            }}
          />

          {/* Contact Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: { xs: "center", md: "left" },
              gap: 2,
            }}
          >
            <Typography
              variant="h6"
              color={colors.white}
              fontWeight={500}
              mb={2}
            >
              ติดต่อเรา
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", md: "column" },
                justifyContent: "center",
                flexWrap: "wrap",
                maxWidth: "500px",
                margin: "auto",
                gap: { xs: 3, md: 1 },
                fontWeight: 300,
              }}
            >
              {contactMenu.map((item) => (
                <Link
                  key={item.name}
                  color={colors.white}
                  href={item.link}
                  underline="none"
                  target="_blank"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={item.img}
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                    alt={`Contact ${item.name}`}
                    onClick={() => { amplitudeTrack(item.key) }}
                  />
                  {item.name}
                </Link>
              ))}
            </Box>
          </Box>
        </Box>

        {/* Divider (Mobile View) */}
        <Divider
          variant="middle"
          sx={{
            borderBottomWidth: 1,
            borderColor: colors.white,
            display: { md: "none" },
          }}
        />

        {/* Additional Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: { xs: "center", md: "left" },
            gap: 4,
          }}
        >
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Typography
              variant="h6"
              fontWeight={500}
              mb={2}
              color={colors.white}
            >
              แอป<span style={{ color: colors.yellow }}>ยู</span>เฟรนด์ โหลดเลย!
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexShrink: 0 }}>
              <img
                src={imgs.appLogo}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "10px",
                  border: "2px solid white",
                }}
                alt="logo ufriend"
              />
              {appLink.map((item, index) => (
                <Link key={index} href={item.link} target="_blank">
                  <img
                    src={item.img}
                    style={{
                      width: "135px",
                      height: "40px",
                    }}
                    alt={`App Link ${index}`}
                    onClick={() => { amplitudeTrack(item.key) }}
                  />
                </Link>
              ))}
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" color={colors.white} mb={2}>
              มาตรฐานการรับรอง
            </Typography>
            <img
              src={imgs.dbdRegistered}
              style={{ width: 124 }}
              alt="DBD Registered"
            />
          </Box>
        </Box>

        {/* Divider (Mobile View) */}
        <Divider
          variant="middle"
          sx={{
            borderBottomWidth: 1,
            borderColor: colors.white,
            display: { md: "none" },
          }}
        />

        {/* Responsive Logo and Tagline (Mobile View) */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            textAlign: "center",
            minWidth: { xs: "100%", sm: "20%" },
          }}
        >
          <img
            src={imgs.appLogo}
            style={{
              width: "72px",
              height: "72px",
              margin: "auto",
            }}
            alt="logo ufriend"
          />
          <Typography variant="h6" fontWeight={500}>
            <span style={{ color: colors.yellow }}>ยู</span>
            <span style={{ color: colors.white }}>เฟรนด์</span>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ color: colors.white }}
            mb={2}
          >
            ชีวิตง่ายดี เพราะมีเพื่อนที่รู้ใจ
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          gap: { md: 3 },
          backgroundColor: colors.footer,
        }}
      >
        <Typography variant="body2" color={colors.white}>
          Copyright © {new Date().getFullYear()} D App Maker Co., LTD All rights
          reserved
        </Typography>
        <Box>
          <Link color={colors.white} variant="body2" target="_blank" href="https://www.ufriend.shop/help/">
            คำถามที่พบบ่อย
          </Link>
          <Typography
            display="inline"
            color={colors.white}
            sx={{ mx: 0.5, opacity: 0.5 }}
          >
            &nbsp;|&nbsp;
          </Typography>
          <Link color={colors.white} variant="body2" target="_blank" href="https://ufriend.shop/%e0%b8%99%e0%b9%82%e0%b8%a2%e0%b8%9a%e0%b8%b2%e0%b8%a2%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b9%80%e0%b8%9b%e0%b9%87%e0%b8%99%e0%b8%aa%e0%b9%88%e0%b8%a7%e0%b8%99%e0%b8%95%e0%b8%b1%e0%b8%a7-new/">
            นโยบายความคุ้มครองข้อมูลส่วนบุคคล
          </Link>
        </Box>
      </Box>
    </div>
  );
}
