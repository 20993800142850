import BaseAPI from '../api'

const path = 'app/option'

interface product {
  type?: string
  model?: string
  storage?: string
  category?: string
}

interface combo {
  code: any,
  storage: any
}

export default class OptionsApi extends BaseAPI {

  static job(): Promise<any> {
    return this.api.get(`${path}/job`).then((res) => res)
  }

  static business(): Promise<any> {
    return this.api.get(`${path}/business`).then((res) => res)
  }

  static registerSalary(): Promise<any> {
    return this.api.get(`${path}/register-salary`).then((res) => res)
  }

  static productModel(param: product): Promise<any> {
    return this.api.get(`${path}/product-model`, { params: { ...param } }).then((res) => res)
  }

  static productStorage(param: product): Promise<any> {
    return this.api.get(`${path}/product-storage`, { params: { ...param } }).then((res) => res)
  }

  static productColor(param: product): Promise<any> {
    return this.api.get(`${path}/product-color`, { params: { ...param } }).then((res) => res)
  }

  static registerBranch(param: product): Promise<any> {
    return this.api.post(`${path}/register-branch`, param).then((res) => res)
  }

  static address(): Promise<any> {
    return this.api.get(`${path}/address`).then((res) => res)
  }

  static productCombo(param: combo): Promise<any> {
    return this.api.post(`${path}/product-combo`, param).then((res) => res)
  }

  static knownUfriend(): Promise<any> {
    return this.api.get(`${path}/known-ufriend`).then((res) => res)
  }

  static facebookExample(): Promise<any> {
    return this.api.get(`${path}/facebook-example`).then((res) => res)
  }
 
}
