import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../constants/colors";

export const CardLocation = (props: any) => {
  const { label, location } = props.item;
  return (
    <Container>
      <ImageStyle src={location?.image_url} alt="" />
      <div style={{ marginTop: 8 }} className="d-flex justify-content-start">
        <Header>{label}</Header>
      </div>
      <div style={{ marginTop: 8 }}>
        <Description>{location?.address}</Description>
        <Description className="mt-2">{location?.description}</Description>
      </div>
      <Footer>
        <Typography style={{ fontSize: 12, textAlign: "end" }}>
          {location?.time}
        </Typography>
        <Linking
          href={location?.location_url}

        >
          ดูแผนที่และเส้นทาง
        </Linking>
      </Footer>
    </Container>
  );
};

const Container = styled("div")(() => ({
  width: "100%",
  height: 550,
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 8,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: 8,
  margin: 8,
}));

const ImageStyle = styled(`img`)(({ theme }) => ({
  width: 180,
  height: 180,

  [theme.breakpoints.down("lg")]: {
    width: 150,
    height: 150,
  },
}));

const Header = styled(Typography)(() => ({
  fontSize: 19,
  fontWeight: 700,
  color: colors.black,
  alignSelf: "flex-start",
}));

const Description = styled(Typography)(() => ({
  fontSize: 16,
  fontStyle: "inherit",
  fontWeight: 400,
  color: colors.textInput,
}));

const Footer = styled(Typography)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  alignItems: "end",
  justifyContent: "flex-end",
}));

const Linking = styled("a")(() => ({
  cursor: "pointer",
  fontSize: 12,
  color: colors.darkGray,
  textAlign: "end",
  textDecoration: "none",
  '&:hover' : {
    color: colors.black,
  }
}));
