import * as amplitude from "@amplitude/analytics-browser";

export const amplitudeTrack = (eventType: string, eventProperties?: string) => {
  amplitude.track(eventType, {
    eventProperties
  });
}

export const amplitudeReset = () => {
  amplitude.reset();
}