import Button from "@mui/material/Button";
import { Col, Modal, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";

import "../../styles/switch.css";

/** CONSTANTS */
import closePopup from "../../assets/icon/close.svg";
import { colors } from "../../constants/colors";

const ModalStyle = styled(Modal)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  "& .modal-content": {
    padding: "1rem 1.5rem 1.5rem",
    borderRadius: 10,
    backdropFilter: "saturate(1) blur(40px)",
    backgroundColor: colors.white_90,
    border: "none !important",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  "& .modal-title": {
    width: "100%",
    h3: {
      textAlign: "center",
      fontSize: 20,
      fontWeight: 600,
      color: colors.themeMainColor,
      "&.header-danger": {
        color: colors.textPrimary,
      },
      "&.header-left": {
        textAlign: "left",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
  },
  "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
    opacity: 0.5,
  },
  "& .modal-footer": {
    display: "flex",
    flexDirection: "row",
    "&:not(.footer-inline)": {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  },
  ".btn-close": {
    position: "absolute",
    top: "16px",
    right: "24px",
    boxShadow: "none !important",
    background: `url(${closePopup}) center/1em auto no-repeat`,
    opacity: 0.6,
    "&:hover": {
      opacity: '0.5',
    },
    [theme.breakpoints.down("xs")]: {
      right: "16px",
    },
  },
}));

const BtnConfirm = styled(Button)(({ theme }) => ({
  width: "max-content !important",
  height: 44,
  fontSize: 16,
  backgroundColor: `${colors.themeMainColor} !important`,
  color: `${colors.white} !important`,
  padding: "0 16px !important",
  boxShadow: "none !important",
  borderRadius: `4px !important`,
  "&:hover": {
    backgroundColor: `${colors.themeMainColor} !important`,
    color: `${colors.white} !important`,
  },
  "&.btn-danger": {
    background: `${colors.red} !important`,
  },
  '&.Mui-disabled': { opacity: 0.7 },
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
  },
  [theme.breakpoints.up("sm")]: {
    marginLeft: ".5rem !important",
  },
}));

const BtnDelete = styled(Button)(({ theme }) => ({
  width: "max-content",
  height: 44,
  fontSize: 16,
  backgroundColor: `#FB5252 !important`,
  color: `${colors.white} !important`,
  padding: "0 16px !important",
  boxShadow: "none !important",
  borderRadius: `4px !important`,
  "&:hover": {
    backgroundColor: `#FB5252 !important`,
    color: `${colors.white} !important`,
  },
  "&.btn-danger": {
    background: `${colors.red} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BtnCancel = styled(Button)(({ theme }) => ({
  width: "max-content",
  height: 44,
  fontSize: 16,
  color: `${colors.themeMainColor} !important `,
  border: `solid 1px ${colors.themeMainColor} !important`,
  padding: "0 16px !important",
  borderRadius: `4px !important`,
  "&.btn-default": {
    color: `${colors.textPrimary} !important `,
    border: `solid 1px ${colors.textExtraLightGray} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    marginRight: ".5rem !important",
  },
}));

type ModalCustomProps = {
  onSubmit?: () => void;
  onReset?: () => void;
  onCancel?: () => void;
  onDelete?: (id: number, obj?: any) => void;
  onClose?: () => void;
  closeButton?: boolean;
  modalTheme?: "default" | "danger";
  classTitle?: string;
  title: string;
  textBtnConfirm?: string;
  textBtnCancel?: string;
  textCancel?: string;
  btnCancelTheme?: "default" | "danger";
  btnConfirmTheme?: "default" | "danger";
  id?: number;
  obj?: any;
  isShowProps?: boolean;
  textBtnDelete?: string;
  component?: any;
  modalStyle?: any;
  size?: "lg" | "sm" | "xl";
  alignFooter?: "start" | "center" | "end";
  fullscreen?:
  | true
  | string
  | "sm-down"
  | "md-down"
  | "lg-down"
  | "xl-down"
  | "xxl-down";
  modalScroll?: boolean;
  footerInline?: boolean;
  autoResetModal?: boolean;
  disabledSubmit?: boolean;
};
export default function ModalCustom(props: ModalCustomProps) {

  return (
    <ModalStyle
      className={`${props.modalStyle || ""}`}
      fullscreen={props?.fullscreen || ""}
      returnFocusAfterClose={false}
      size={props.size || "sm"}
      show={props.isShowProps}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="border-0 text-center mx-auto my-0 p-0 w-100 mb-3"
        closeButton={props.closeButton || false}
        onHide={() => (
          props.onClose && props.onClose()
        )}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <h3
            className={`m-0 ${props.modalTheme ? `header-${props.modalTheme}` : ""
              } ${props.classTitle}`}
          >
            {props.title}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`px-0 p-0 ${props.modalScroll ? "custom-scroll overflow-auto" : ""
          }`}
      >
        {props.component}
      </Modal.Body>
      <Modal.Footer
        className={`flex-center ${props.alignFooter
          ? `justify-content-${props.alignFooter}`
          : "justify-content-center"
          } ${props.footerInline ? "footer-inline" : ""} border-0 p-0 mt-3`}
      >
        <Row
          className={`w-100 m-0 ${props.alignFooter
            ? `justify-content-${props.alignFooter}`
            : "justify-content-center"
            }`}
        >
          {(props?.onReset || props?.textBtnCancel) && (
            <Col
              xs={
                (props.textBtnCancel) || props.footerInline ? 6 : 12
              }
              md={"auto"}
              className={`mt-0 px-1 px-md-0 ${props.alignFooter === "end" ? "ml-auto" : ""
                }`}
            >
              <BtnCancel
                fullWidth
                className={`${props.modalTheme === "danger"
                  ? "btn-default"
                  : props.modalTheme && props.modalTheme !== "default"
                    ? `btn-${props.modalTheme}`
                    : ""
                  } ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ""}`}
                onClick={() => {
                  props.onReset && props.onReset();

                }}
              >
                {props.textBtnCancel}
              </BtnCancel>
            </Col>
          )}
          {(props?.onCancel && props?.textCancel) && (
            <Col
              xs={props.footerInline ? 6 : 12}
              md={"auto"}
              className={`${!props.footerInline ? "mt-2" : ""
                } mt-md-0 px-1 px-md-0`}
            >
              <BtnCancel
                fullWidth
                className={`${props.modalTheme === "danger"
                  ? "btn-default"
                  : props.modalTheme && props.modalTheme !== "default"
                    ? `btn-${props.modalTheme}`
                    : ""
                  } ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ""}`}
                onClick={() => props.onCancel && props.onCancel()}
              >
                {props.textCancel}
              </BtnCancel>
            </Col>
          )}
          {props.textBtnDelete && props.id && (
            <Col xs={6} md={"auto"} className="mt-0 mt-md-0 px-1 px-md-0">
              <BtnDelete
                fullWidth
                className={`${props.modalTheme === "danger"
                  ? "btn-default"
                  : props.modalTheme && props.modalTheme !== "default"
                    ? `btn-${props.modalTheme}`
                    : ""
                  } ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ""}`}
                onClick={() => {
                  if (props.obj) {
                    if (props?.onDelete && props?.id && props?.obj)
                      props.onDelete(props?.id, props?.obj);
                  } else if (props?.onDelete && props?.id)
                    props.onDelete(props?.id);

                }}
              >
                {props.textBtnDelete}
              </BtnDelete>
            </Col>
          )}
          {props?.onSubmit && props?.textBtnConfirm && (
            <Col
              xs={props.footerInline ? 6 : 12}
              md={"auto"}
              className={`${!props.footerInline ? "mt-2" : ""
                } mt-md-0 px-1 px-md-0`}
            >
              <BtnConfirm
                fullWidth
                className={`btn-submit ${props.modalTheme ? `btn-${props.modalTheme}` : ""
                  } ${props.btnConfirmTheme ? `btn-${props.btnConfirmTheme}` : ""
                  }`}
                disabled={props?.disabledSubmit || false}
                onClick={() => props.onSubmit && props.onSubmit()}
              >
                {props.textBtnConfirm}
              </BtnConfirm>
            </Col>
          )}
        </Row>
      </Modal.Footer>
    </ModalStyle>
  );
}
