import { Box, Link, Typography } from "@mui/material";
import { imgs } from "../../../constants/images";

interface props {
    titleName: string
}

export default function Contact(props: props) {
    return (
        <>
            <Typography variant="h5" fontWeight={500} mb={3} mt={1}>
                {props.titleName} คลิกที่นี่
            </Typography>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap={2}
                width="40%"
                mx="auto"
                my={3}
            >
                <Link href="https://m.me/189675452607207" target="_blank">
                    <img src={imgs.facebookLogo} />
                </Link>
                <Link href="https://lin.ee/FJBOd2R" target="_blank">
                    <img src={imgs.lineLogo} />
                </Link>
                <Link href="tel:+66646169699" target="_blank">
                    <img src={imgs.phoneCall} />
                </Link>
            </Box>
            <Typography variant="h6">เบอร์โทรศัพท์: 064-616-9699</Typography>
        </>
    )
}