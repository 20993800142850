import { styled } from '@mui/material/styles'
import { Row } from 'react-bootstrap'

export const Container = styled(`div`)(({ theme }) => ({
    padding: "20px 350px",

    [theme.breakpoints.down('lg')]: {
        padding: "10px 50px",
    },
    [theme.breakpoints.down('md')]: {
        padding: "10px 8px",
    }
}))

export const MarginRow = styled(Row)(() => ({
    marginTop: 8,

}))

export const CenterContainer = styled(Row)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))