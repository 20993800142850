import { colors } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Row } from 'react-bootstrap'

export const Container = styled(`div`)(({ theme }) => ({
    padding: "20px 150px",

    [theme.breakpoints.down('lg')]: {
        padding: "10px 50px",
    },
    [theme.breakpoints.down('md')]: {
        padding: "10px 8px",
    }
}))

export const MarginRow = styled(Row)(() => ({
    // marginTop: 8,

}))

export const CenterContainer = styled(Row)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const UploadFileContainer = styled(Row)(() => ({
    border: `1px dashed ${colors.blue}`,
    margin: '0px 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}))