import axios from "axios";

export const apiUrl = process.env.REACT_APP_API;
const instance = axios.create({
  baseURL: apiUrl || process.env.REACT_APP_API,
  timeout: 100000,
  headers: {
    // "Content-Type": "application/json",
    'Content-type': 'multipart/form-data'
  },
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log(error);
  }
);

export default class BaseAPIUpload {
  static api = instance;
}

