import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { colors } from '../../constants/colors'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const CustomButton = styled(Button)<{ mode?: 'add' | 'default' | 'del' }>(({ mode }) => {
  const colorMain = mode === 'del' ? colors.danger : colors.themeMainColor
  return ({
    '&.Mui-disabled': { opacity: 0.3 },
    '&.MuiBox': { display: 'flex', justifyContent: 'center' },
    '&.MuiButton-text': {
      color: `${colorMain} !important`
    },
    '&.MuiButton-outline': {
      color: `${colorMain} !important`,
      border: `1px solid ${colorMain} !important`,
      padding: '9px 16px !important',
    },
    '&.MuiButton-contained': {
      background: `${colorMain} !important`,
      backgroundColor: `${colorMain} !important`,
      color: `${colors.white} !important`,
      padding: mode === 'add' ? '8px 16px !important' : '10px 16px !important',
      border: 'none !important',
      '&:hover': {
        backgroundColor: `${colorMain} !important`,
        color: `${colors.white} !important`
      },
      '& .MuiButton-startIcon': {
        marginLeft: 0
      }
    },
    '&.btn-style': {
      borderRadius: '4px',
    }
  })
})
export const ButtonBase = styled(Button)(() => ({
  height: 44,
  fontSize: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem'
}))

export default function ButtonCustom(props: any) {
  return (
    <Box sx={{ margin: 'auto', display: 'flex', justifyContent: 'center', ...props.style }}>
      <CustomButton
        sx={{ ...props.style, ...props.btnStyle }}
        endIcon={props.endIcon}
        startIcon={props.startIcon}
        onClick={props.onClick}
        type={props.type}
        className={`btn-style ${props.className} shadow-none`}
        disabled={props.disabled}
        variant={props.variant || 'contained'}
        mode={props.mode}
      >
        {(props.mode === 'add' && (
          <span style={{ fontSize: '1rem' }}>
            <AddCircleIcon className="mr-2" style={{ fontSize: 20 }} />
            <span className="align-middle">{props.textButton}</span>
          </span>
        )) ||
          props.textButton}
        {props.input && (
          <input type='file'></input>
        )}
      </CustomButton>
    </Box>
  )
}

ButtonCustom.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  textButton: PropTypes.any,
  style: PropTypes.any,
  endIcon: PropTypes.any,
  startIcon: PropTypes.any,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  mode: PropTypes.oneOf(['add', 'del', 'default']),
  btnStyle: PropTypes.any,
  input: PropTypes.bool
}
