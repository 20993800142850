import errNoImg from '../assets/images/image-not-load.png'
import warningAlert from '../assets/images/warning.jpg'
import appLogo from '../assets/images/app-logo.png'
import logoNew from '../assets/images/logo_new.png'

import appleStore from '../assets/images/apple-store.png'
import playStore from '../assets/images/google-play-store.png'
import dbdRegistered from '../assets/images/DBD-Registered.png'
import buttonWeb from '../assets/images/button-web.png'
import lineLogo from '../assets/images/line.png'
import facebookLogo from '../assets/images/logofb.png'
import phoneCall from '../assets/images/phone-call.png'
import iPhoneXAll from '../assets/images/iPhoneX-all.jpg'
import iPhoneXSAll from '../assets/images/iPhoneXS-all.jpg'
import iPhoneXRAll from '../assets/images/iPhoneXR-all.jpg'
import iPhone11All from '../assets/images/iPhone11-all.jpg'

export const imgs = {
    errNoImg: errNoImg,
    warningAlert: warningAlert,
    appLogo: appLogo,
    newLogo: logoNew,
    appleStore: appleStore,
    playStore: playStore,
    dbdRegistered: dbdRegistered,
    buttonWeb: buttonWeb,
    lineLogo: lineLogo,
    facebookLogo: facebookLogo,
    phoneCall: phoneCall,
    iPhoneXAll: iPhoneXAll,
    iPhoneXSAll: iPhoneXSAll,
    iPhoneXRAll: iPhoneXRAll,
    iPhone11All: iPhone11All,
}


export const onImgError = ({ currentTarget }: any) => {
    currentTarget.onerror = null // prevents looping
    currentTarget.src = imgs.errNoImg
}