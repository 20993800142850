import ButtonCustom from "../../../components/button/buttonCustom";
import { Col } from "react-bootstrap";
import { optionDegree, optionRegisterCollge, optionsExperience, optionsSalaryStudent } from "../../../utils/options";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import * as Styles from "./layout-career.style";
import { useCallback, useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import InputTextField from "../../../components/input/inputTextField";
import { colors } from "../../../constants/colors";
import { CAREER, careerLocation, careerOcc, careerVal } from "../../../utils/enum";
import { monthOnly } from "../../../utils/date.utils";
import { fromRegister } from "../type-register";
import AuthenUploadApi from "../../../api/authen/authenUpload.api";
import _ from "lodash";
import UploadImageList from "../../../components/upload/list";
import UploadImage from "../../../components/upload/uploadFile";
import HelperText from "../../../components/input/helperText";
import { amplitudeTrack } from "../../../utils/amplitude/amplitude";
import OptionsApi from "../../../api/option/option.api";

interface props {
  onClickNext: () => void;
  onClickBack: () => void;
  data: any
  onChange?: (value: any) => void
  downPayment: any
}

const initStateErrorMessage = {
  CAREER_TYPE: ``,
  CAREER_OCCUPATION: ``,
  CAREER_BUSINESS: ``,
  CAREER_COMPANYNAME: ``,
  CAREER_COMPANYLOCATION: ``,
  CAREER_POSITION: ``,
  CAREER_EXPERIENCE: ``,
  CAREER_COMPANYPHONENUMBER: ``,
  CAREER_COLLEGE: ``,
  CAREER_FACULTY: ``,
  CAREER_BRANCH: ``,
  CAREER_DEGREE: ``,
  CAREER_YEAR: ``,
  CAREER_GRADE: ``,
  CAREER_SALARY: ``,
  CAREER_CHANELSALARY: ``,
  CAREER_PROVEOWN: ``,
  CAREER_SALESCHANNELS: ``,
  CAREER_PASSWORD: ``,
  CAREER_STATEMENTTYPE: ``,
  CAREER_GOVERNMENT: ``,
  CAREER_PROVEOWNIMG: ``,
  CAREER_LINK: ``,
  CAREER_SALESIMG: ``,
  CAREER_STATEMENT: ``,
  CAREER_RIDER: ``,
  CAREER_PROVEWORK: ``,
  CAREER_STUDENTIMG: ``,
  CAREER_TRANSCRIPT: ``,

  CAREER_STATEMENTYPE: ``
}

const initLoading = {
  statement: false,
  governmentOfficial: false,
  proveOwnBusinessImage: false,
  salesChannelsImage: false,
  riderAppication: false,
  proveWork: false,
  studentImages: false,
  transcriptOrTermFees: false,
}


export default function LayoutCareer(props: props) {
  const [data, setData] = useState<fromRegister>(props.data)
  const [registerSalary, setRegisterSalary] = useState<any[]>([])
  const [occupationData, setOccupationData] = useState<any[]>([])
  const [businessData, setBusinessData] = useState<any[]>([])


  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [loadingImg, setLoadingImg] = useState(initLoading)

  const prev3Month = monthOnly(new Date().getMonth() - 2)
  const month = monthOnly(new Date().getMonth())
  const [typeProd, setTypeProd] = useState<string>(props.data.type)

  const [type, setType] = useState<string>(props.data.job?.type)

  const [occupation, setOccupation] = useState<string>(props.data?.job?.occupation)
  const [business, setBusiness] = useState<string>(props.data?.job?.business)
  const [companyName, setCompanyName] = useState<string>(props.data?.job?.companyName)
  const [companyLocation, setCompanyLocation] = useState<string>(props.data?.job?.companyLocation)
  const [position, setPosition] = useState<string>(props.data?.job?.position)
  const [experience, setExperience] = useState<string>(props.data?.job?.experience)
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState<string>(props.data?.job?.companyPhoneNumber)

  const [college, setCollege] = useState<string>(props.data?.job?.college)
  const [faculty, setFaculty] = useState<string>(props.data?.job?.faculty)
  const [branch, setBranch] = useState<string>(props.data?.job?.branch)
  const [degree, setDegree] = useState<string>(props.data?.job?.degree)
  const [year, setYear] = useState<string>(props.data?.job?.year)
  const [grade, setGrade] = useState<string>(props.data?.job?.grade)

  const [salary, setSalary] = useState<string>(props.data?.job?.salary)
  const [chanelSalary, setChanelSalary] = useState<string>(props.data?.job?.chanelSalary)

  const [proveOwnBusinessImageFlag, setProveOwnBusinessImageFlag] = useState<string>(props.data?.proveOwnBusinessImageFlag === true ? 'true' : 'false')
  const [salesChannels, setSalesChannels] = useState<string>(props.data?.salesChannels || '')

  const [password, setPassword] = useState<string>(props.data?.statement?.password || '')
  const [months, setMonths] = useState<string>(props.data?.statement?.month)
  const [statementType, setStatementType] = useState<string>(props.data?.statement?.type)
  const [link, setLink] = useState<string>(props.data?.salesChannelsImage?.link || '')

  const [governmentOfficial, setGovernmentOfficial] = useState<any>(props.data?.governmentOfficial || [])
  const [proveOwnBusinessImage, setProveOwnBusinessImage] = useState<any>(props.data?.proveOwnBusinessImage || [])
  const [salesChannelsImage, setSalesChannelsImage] = useState<any>(props.data?.salesChannelsImage?.images || [])
  const [statement, setStatement] = useState<any>(props.data?.statement?.images || [])
  const [riderAppication, setRiderAppication] = useState<any>(props.data?.riderAppication || [])
  const [proveWork, setProveWork] = useState<any>(props.data?.proveWork || [])
  const [studentImages, setStudentImages] = useState<any>(props.data.job?.studentImages || [])
  const [transcriptOrTermFees, setTranscriptOrTermFees] = useState<any>(props.data.job?.transcriptOrTermFees || [])

  const loadJob = useCallback(() => {
    OptionsApi.job().then((res) => {
      if (res.status === 200) {
        setOccupationData(res.data.job)
      }
    })
  }, [])

  useEffect(() => {
    loadJob()
  }, [loadJob]);

  const loadBusiness = useCallback(() => {
    OptionsApi.business().then((res) => {
      if (res.status === 200) {
        setBusinessData(res.data.business)
      }
    })
  }, [])

  useEffect(() => {
    loadBusiness()
  }, [loadBusiness]);

  const loadRegisterSalary = useCallback(() => {
    OptionsApi.registerSalary().then((res) => {
      if (res.status === 200) {
        setRegisterSalary(res.data.result)
      }
    })
  }, [])

  useEffect(() => {
    loadRegisterSalary()
  }, [loadRegisterSalary]);

  useEffect(() => {
    if (props.onChange) props.onChange(data)
  }, [data])

  const onUpload = (event: any, types: string) => {
    if(event.target.files[0]){
      fromData(event.target.files[0], types)
    }
  }

  const fromData = async (image: any, types: string) => {
    console.log(types, 'types')
    const formData = new FormData();
    formData.append("file", image);
    switch (types) {
      case 'governmentOfficial':
        setLoadingImg({ ...loadingImg, ...{ governmentOfficial: true } })
        break;
      case 'proveOwnBusinessImage':
        setLoadingImg({ ...loadingImg, ...{ proveOwnBusinessImage: true } })
        break;
      case 'salesChannelsImage':
        setLoadingImg({ ...loadingImg, ...{ salesChannelsImage: true } })
        break;
      case 'statement':
        setLoadingImg({ ...loadingImg, ...{ statement: true } })
        break;
      case 'riderAppication':
        setLoadingImg({ ...loadingImg, ...{ riderAppication: true } })
        break;
      case 'proveWork':
        setLoadingImg({ ...loadingImg, ...{ proveWork: true } })
        break;
      case 'studentImages':
        setLoadingImg({ ...loadingImg, ...{ studentImages: true } })
        break;
      case 'transcriptOrTermFees':
        setLoadingImg({ ...loadingImg, ...{ transcriptOrTermFees: true } })
        break;
      default:
        break;
    }
    await AuthenUploadApi.registerUpload(formData).then((res: any) => {
      if (res.status === 200) {
        if (types === 'governmentOfficial') {
          setGovernmentOfficial([
            {
              fileName: res.data.file.fileName,
              mimeType: res.data.file.mimetype,
              name: image.name,
              img: URL.createObjectURL(image)
            }
          ])
          setLoadingImg({ ...loadingImg, ...{ governmentOfficial: false } })
          setErrorMessage({ ...errorMessage, CAREER_GOVERNMENT: '' })
        } else if (types === 'proveOwnBusinessImage') {
          const arr: any = proveOwnBusinessImage ? proveOwnBusinessImage : []
          arr.push({
            fileName: res.data.file.fileName,
            mimeType: res.data.file.mimetype,
            name: image.name,
            img: URL.createObjectURL(image)
          })
          setProveOwnBusinessImage(arr)
          setLoadingImg({ ...loadingImg, ...{ proveOwnBusinessImage: false } })
          setErrorMessage({ ...errorMessage, CAREER_PROVEOWNIMG: '' })
        } else if (types === 'salesChannelsImage') {
          const arr: any = salesChannelsImage ? salesChannelsImage : []
          arr.push({
            fileName: res.data.file.fileName, mimeType: res.data.file.mimetype,
            name: image.name, img: URL.createObjectURL(image)
          })
          setSalesChannelsImage(arr)
          setLoadingImg({ ...loadingImg, ...{ salesChannelsImage: false } })
          setErrorMessage({ ...errorMessage, CAREER_SALESIMG: '' })
        } else if (types === 'statement') {
          const arr: any = statement ? statement : []
          arr.push({
            fileName: res.data.file.fileName,
            mimeType: res.data.file.mimetype,
            name: image.name,
            img: URL.createObjectURL(image)
          })
          setStatement(arr)
          setLoadingImg({ ...loadingImg, ...{ statement: false } })
          setErrorMessage({ ...errorMessage, CAREER_STATEMENT: '' })
          if (type === "นักเรียน/นักศึกษา") {
            amplitudeTrack('3_Job_P_uploaddocstudent_success')
          } else {
            amplitudeTrack('3_Job_P_uploaddoc_success')
          }
        } else if (types === 'riderAppication') {
          const arr: any = riderAppication ? riderAppication : []
          arr.push({
            fileName: res.data.file.fileName,
            mimeType: res.data.file.mimetype,
            name: image.name,
            img: URL.createObjectURL(image)
          })
          setRiderAppication(arr)
          setLoadingImg({ ...loadingImg, ...{ riderAppication: false } })
          setErrorMessage({ ...errorMessage, CAREER_RIDER: '' })
        } else if (types === 'proveWork') {
          const arr: any = proveWork ? proveWork : []
          arr.push({
            fileName: res.data.file.fileName,
            mimeType: res.data.file.mimetype,
            name: image.name,
            img: URL.createObjectURL(image)
          })
          setProveWork(arr)
          amplitudeTrack('3_Job_P_uploadbus_success')
          setLoadingImg({ ...loadingImg, ...{ proveWork: false } })
          setErrorMessage({ ...errorMessage, CAREER_PROVEWORK: '' })
        } else if (types === 'studentImages') {
          setStudentImages([{
            fileName: res.data.file.fileName,
            mimeType: res.data.file.mimetype,
            name: image.name,
            img: URL.createObjectURL(image)
          }])
          setErrorMessage({ ...errorMessage, CAREER_STUDENTIMG: '' })
          setLoadingImg({ ...loadingImg, ...{ studentImages: false } })
          amplitudeTrack('3_Job_P_uploadcardstudent_success')
        } else if (types === 'transcriptOrTermFees') {
          setTranscriptOrTermFees([
            {
              fileName: res.data.file.fileName,
              mimeType: res.data.file.mimetype,
              name: image.name,
              img: URL.createObjectURL(image)
            }
          ])
          setLoadingImg({ ...loadingImg, ...{ transcriptOrTermFees: false } })
          setErrorMessage({ ...errorMessage, CAREER_TRANSCRIPT: '' })
        }
      }
    }).catch(() => {
      switch (types) {
        case 'governmentOfficial':
          setLoadingImg({ ...loadingImg, ...{ governmentOfficial: false } })
          return setErrorMessage({ ...errorMessage, CAREER_GOVERNMENT: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'proveOwnBusinessImage':
          setLoadingImg({ ...loadingImg, ...{ proveOwnBusinessImage: false } })
          return setErrorMessage({ ...errorMessage, CAREER_PROVEOWNIMG: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'salesChannelsImage':
          setLoadingImg({ ...loadingImg, ...{ salesChannelsImage: false } })
          return setErrorMessage({ ...errorMessage, CAREER_SALESIMG: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'statement':
          if (type === "นักเรียน/นักศึกษา") {
            amplitudeTrack('3_Job_P_uploaddocstudent_fail')
          } else {
            amplitudeTrack('3_Job_P_uploaddoc_fail')
          }
          setLoadingImg({ ...loadingImg, ...{ statement: false } })
          return setErrorMessage({ ...errorMessage, CAREER_STATEMENT: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'riderAppication':
          setLoadingImg({ ...loadingImg, ...{ riderAppication: false } })
          return setErrorMessage({ ...errorMessage, CAREER_RIDER: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'proveWork':
          setLoadingImg({ ...loadingImg, ...{ proveWork: false } })
          amplitudeTrack('3_Job_P_uploadbus_fail')
          return setErrorMessage({ ...errorMessage, CAREER_PROVEWORK: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'studentImages':
          setLoadingImg({ ...loadingImg, ...{ studentImages: false } })
          amplitudeTrack('3_Job_P_uploadcardstudent_fail')
          return setErrorMessage({ ...errorMessage, CAREER_STUDENTIMG: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        case 'transcriptOrTermFees':
          setLoadingImg({ ...loadingImg, ...{ transcriptOrTermFees: false } })
          return setErrorMessage({ ...errorMessage, CAREER_TRANSCRIPT: 'อัพโหลดไฟล์ไม่สำเร็จ' })
        default:
          break;
      }
    })
  }

  const onDelete = async (val: any, index: number, type: string) => {
    if (type === 'governmentOfficial') {
      const img = governmentOfficial?.filter((data: any) => data.fileName !== val.fileName)
      setGovernmentOfficial(img)
    } else if (type === 'proveOwnBusinessImage') {
      const img = proveOwnBusinessImage?.filter((data: any) => data.fileName !== val.fileName)
      setProveOwnBusinessImage(img)
    } else if (type === 'salesChannelsImage') {
      const img = salesChannelsImage?.filter((data: any) => data.fileName !== val.fileName)
      setSalesChannelsImage(img)
    } else if (type === 'statement') {
      const img = statement?.filter((data: any) => data.fileName !== val.fileName)
      setStatement(img)
    } else if (type === 'riderAppication') {
      const img = riderAppication?.filter((data: any) => data.fileName !== val.fileName)
      setRiderAppication(img)
    } else if (type === 'proveWork') {
      const img = proveWork?.filter((data: any) => data.fileName !== val.fileName)
      setProveWork(img)
    } else if (type === 'studentImages') {
      const img = studentImages?.filter((data: any) => data.fileName !== val.fileName)
      setStudentImages(img)
    } else if (type === 'transcriptOrTermFees') {
      const img = transcriptOrTermFees?.filter((data: any) => data.fileName !== val.fileName)
      setTranscriptOrTermFees(img)
    }
  }

  const clearData = () => {
    setOccupation('')
    setBusiness('')
    setCompanyName('')
    setCompanyLocation('')
    setPosition('')
    setExperience('')
    setCompanyPhoneNumber('')

    setCollege('')
    setFaculty('')
    setBranch('')
    setDegree('')
    setYear('')
    setGrade('')

    setSalary('')
    setChanelSalary('')

    setProveOwnBusinessImageFlag('')
    setSalesChannels('')

    setPassword('')
    setMonths('')
    setStatementType('')


    setGovernmentOfficial([])
    setProveOwnBusinessImage([])
    setSalesChannelsImage([])
    setStatement([])
    setRiderAppication([])
    setProveWork([])
    setStudentImages([])
    setTranscriptOrTermFees([])
    setErrorMessage(initStateErrorMessage)
  }

  const clearDataPersonal = () => {
    setBusiness('')
    setCompanyName('')
    setCompanyLocation('')
    setPosition('')
    setExperience('')
    setCompanyPhoneNumber('')

    setCollege('')
    setFaculty('')
    setBranch('')
    setDegree('')
    setYear('')
    setGrade('')

    setSalary('')
    setChanelSalary('')

    setProveOwnBusinessImageFlag('')
    setSalesChannels('')

    setPassword('')
    setMonths('')
    setStatementType('')


    setGovernmentOfficial([])
    setProveOwnBusinessImage([])
    setSalesChannelsImage([])
    setStatement([])
    setRiderAppication([])
    setProveWork([])
    setStudentImages([])
    setTranscriptOrTermFees([])
    setErrorMessage(initStateErrorMessage)
  }

  useEffect(() => {
    if (occupation) {
      if (CAREER.TEMPORARY === occupation || CAREER.OWNER === occupation) {
        setMonths(`${prev3Month}-${month}`)
        setStatementType('3MONTH')
      }
    }
  }, [occupation]);

  const submitData = async (types: string) => {
    let conditionJob: any = {}
    let conproveWork
    let conprovestatement
    let conriderAppication
    let congovernmentOfficial
    let conproveOwnBusinessImageFlag
    let conproveOwnBusinessImage
    let consalesChannels
    let consalesChannelsImage
    if (type) conditionJob = { ...conditionJob, type: type }
    if (occupation) conditionJob = { ...conditionJob, occupation: occupation }
    if (business) conditionJob = { ...conditionJob, business: business }
    if (companyName) conditionJob = { ...conditionJob, companyName: companyName }
    if (companyLocation) conditionJob = { ...conditionJob, companyLocation: companyLocation }
    if (position) conditionJob = { ...conditionJob, position: position }
    if (experience) conditionJob = { ...conditionJob, experience: experience }
    if (companyPhoneNumber) conditionJob = { ...conditionJob, companyPhoneNumber: companyPhoneNumber }
    if (salary) conditionJob = { ...conditionJob, salary: salary }
    if (college) conditionJob = { ...conditionJob, college: college }
    if (faculty) conditionJob = { ...conditionJob, faculty: faculty }
    if (branch) conditionJob = { ...conditionJob, branch: branch }
    if (degree) conditionJob = { ...conditionJob, degree: degree }
    if (year) conditionJob = { ...conditionJob, year: year }
    if (grade) conditionJob = { ...conditionJob, grade: grade }
    if (chanelSalary) conditionJob = { ...conditionJob, chanelSalary: chanelSalary }
    if (studentImages && type === 'นักเรียน/นักศึกษา') conditionJob = { ...conditionJob, studentImages: studentImages }
    if (transcriptOrTermFees && type === 'นักเรียน/นักศึกษา') conditionJob = { ...conditionJob, transcriptOrTermFees: transcriptOrTermFees }
    if (statement) conprovestatement = { images: statement, type: statementType, month: months, password: password ? password : '' }
    if (proveWork && type === 'บุคคลทั่วไป') conproveWork = proveWork
    if (riderAppication && type === 'บุคคลทั่วไป') conriderAppication = riderAppication
    if (governmentOfficial && type === 'บุคคลทั่วไป') congovernmentOfficial = governmentOfficial
    if (proveOwnBusinessImageFlag && type === 'บุคคลทั่วไป') conproveOwnBusinessImageFlag = proveOwnBusinessImageFlag === 'true' ? true : false
    if (proveOwnBusinessImage && type === 'บุคคลทั่วไป') conproveOwnBusinessImage = proveOwnBusinessImage
    if (salesChannels && type === 'บุคคลทั่วไป') consalesChannels = salesChannels
    if (salesChannelsImage && type === 'บุคคลทั่วไป') consalesChannelsImage = { images: salesChannelsImage, link: link }

    await setData({
      ...data,
      job: conditionJob,
      statement: conprovestatement,
      proveWork: conproveWork,
      riderAppication: conriderAppication,
      governmentOfficial: congovernmentOfficial,
      proveOwnBusinessImageFlag: conproveOwnBusinessImageFlag,
      proveOwnBusinessImage: conproveOwnBusinessImage,
      salesChannels: consalesChannels,
      salesChannelsImage: consalesChannelsImage,
    })
    if (types === 'NEXT') {
      setTimeout(() => {
        return props.onClickNext && props.onClickNext()
      }, 500);
    } else if (types === 'BACK') {
      setTimeout(() => {
        return props.onClickBack && props.onClickBack()
      }, 500);
    }
  }

  const onSubmit = async () => {
    if (type === 'บุคคลทั่วไป') {
      if (!occupation) {
        const msg: any = {
          CAREER_OCCUPATION: !occupation ? 'โปรดเลือกลักษณะอาชีพ' : '',
        }
        setErrorMessage({ ...initStateErrorMessage, ...msg })
        return false
      } else if (occupation === 'พนักงานราชการ') {
        if (!occupation || !business || !companyName || !companyLocation || !position || !experience ||
          !companyPhoneNumber || !salary || (statementType == '3MONTH' && !password) ||
          !statementType || _.isEmpty(statement) || _.isEmpty(governmentOfficial)) {
          const msg: any = {
            CAREER_OCCUPATION: !occupation ? 'โปรดเลือกลักษณะอาชีพ' : '',
            CAREER_BUSINESS: !business ? 'โปรดเลือกสังกัดหน่วยงาน' : '',
            CAREER_COMPANYNAME: !companyName ? 'โปรดกรอกชื่อหน่วยงาน' : '',
            CAREER_COMPANYLOCATION: !companyLocation ? 'โปรดกรอกที่อยู่หน่วยงาน' : '',
            CAREER_POSITION: !position ? 'โปรดกรอกตำแหน่งงาน' : '',
            CAREER_EXPERIENCE: !experience ? 'โปรดเลือกอายุงาน' : '',
            CAREER_SALARY: !salary ? 'โปรดเลือกรายได้ต่อเดือน' : '',
            CAREER_COMPANYPHONENUMBER: !companyPhoneNumber ? 'โปรดกรอกเบอร์ที่ทำงาน' : '',
            CAREER_STATEMENT: _.isEmpty(statement) ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_STATEMENTYPE: !statementType ? 'โปรดเลือกเอกสารรายได้' : '',
            CAREER_PASSWORD: !password && statementType == '3MONTH' ? 'โปรดกรอกรหัสเข้าไฟล์' : '',
            CAREER_GOVERNMENT: _.isEmpty(governmentOfficial) ? 'โปรดอัพโหลดไฟล์เอกสาร' : ''
          }
          setErrorMessage({ ...initStateErrorMessage, ...msg })
          return false
        } else {
          submitData('NEXT')
          amplitudeTrack('3_Job_N_next')
        }
      } else if (occupation === 'พนักงานเงินเดือน') {
        if (!occupation || !business || !companyName || !companyLocation || !position || !experience ||
          !companyPhoneNumber || !salary || (statementType !== 'CURRENT' && !password) ||
          !statementType || _.isEmpty(statement)) {
          const msg: any = {
            CAREER_OCCUPATION: !occupation ? 'โปรดเลือกลักษณะอาชีพ' : '',
            CAREER_BUSINESS: !business ? 'โปรดเลือกลักษณะงานประจำ' : '',
            CAREER_COMPANYNAME: !companyName ? 'โปรดกรอกชื่อบริษัท' : '',
            CAREER_COMPANYLOCATION: !companyLocation ? 'โปรดกรอกที่อยู่ที่ทำงาน' : '',
            CAREER_POSITION: !position ? 'โปรดกรอกตำแหน่งงาน' : '',
            CAREER_EXPERIENCE: !experience ? 'โปรดเลือกอายุงาน' : '',
            CAREER_SALARY: !salary ? 'โปรดเลือกรายได้ต่อเดือน' : '',
            CAREER_COMPANYPHONENUMBER: !companyPhoneNumber ? 'โปรดกรอกเบอร์ที่ทำงาน' : '',
            CAREER_STATEMENT: _.isEmpty(statement) ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_STATEMENTYPE: !statementType ? 'โปรดเลือกเอกสารรายได้' : '',
            CAREER_PASSWORD: (!password && statementType !== 'CURRENT') ? 'โปรดกรอกรหัสเข้าไฟล์' : '',
          }
          setErrorMessage({ ...initStateErrorMessage, ...msg })
          return false
        } else {
          submitData('NEXT')
          amplitudeTrack('3_Job_N_next')
        }
      } else if (occupation === 'พนักงานชั่วคราว/พนักงานอิสระ') {
        if (!occupation || !business || !companyName || !companyLocation || !position || !experience ||
          !companyPhoneNumber || !salary || !password || _.isEmpty(statement) || (_.isEmpty(riderAppication) && business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่') ||
          (_.isEmpty(proveWork) && business !== 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่')) {
          const msg: any = {
            CAREER_OCCUPATION: !occupation ? 'โปรดเลือกลักษณะอาชีพ' : '',
            CAREER_BUSINESS: !business ? 'โปรดเลือกลักษณะงานที่ทำ' : '',
            CAREER_COMPANYNAME: !companyName ? 'โปรดกรอกชื่อร้านที่ทำงาน/บริษัทที่จ้าง' : '',
            CAREER_COMPANYLOCATION: !companyLocation ? 'โปรดกรอกที่อยู่ร้านที่ทำงาน/บริษัทที่จ้าง' : '',
            CAREER_POSITION: !position ? 'โปรดกรอกตำแหน่งงาน' : '',
            CAREER_EXPERIENCE: !experience ? 'โปรดเลือกอายุงาน' : '',
            CAREER_SALARY: !salary ? 'โปรดเลือกรายได้ต่อเดือน' : '',
            CAREER_PASSWORD: !password ? 'โปรดกรอกรหัสเข้าไฟล์' : '',
            CAREER_COMPANYPHONENUMBER: !companyPhoneNumber ? 'โปรดกรอกเบอร์ที่ทำงาน' : '',
            CAREER_STATEMENT: _.isEmpty(statement) ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_RIDER: _.isEmpty(riderAppication) && business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_PROVEWORK: _.isEmpty(proveWork) && business !== 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
          }
          setErrorMessage({ ...initStateErrorMessage, ...msg })
          return false
        } else {
          submitData('NEXT')
          amplitudeTrack('3_Job_N_next')
        }
      } else if (occupation === 'เจ้าของกิจการ') {
        if (!occupation || !business || !companyName || !companyLocation || !position || !experience ||
          !companyPhoneNumber || !salary || !password || _.isEmpty(statement) || !salesChannels ||
          (typeProd === 'REFINANCE' && salesChannels === 'OFFLINE' && _.isEmpty(salesChannelsImage))
          || (typeProd === 'REFINANCE' && salesChannels === 'ONLINE' && !link)
          || _.isEmpty(proveOwnBusinessImage) || _.isEmpty(proveOwnBusinessImageFlag) || !salesChannels) {
          const msg: any = {
            CAREER_OCCUPATION: !occupation ? 'โปรดเลือกลักษณะอาชีพ' : '',
            CAREER_BUSINESS: !business ? 'โปรดเลือกลักษณะธุรกิจ ' : '',
            CAREER_COMPANYNAME: !companyName ? 'โปรดกรอกชื่อกิจการ/ร้านค้า' : '',
            CAREER_COMPANYLOCATION: !companyLocation ? 'โปรดกรอกที่อยู่กิจการ/ร้านค้า ' : '',
            CAREER_POSITION: !position ? 'โปรดกรอกตำแหน่งงาน' : '',
            CAREER_EXPERIENCE: !experience ? 'โปรดเลือกอายุงาน' : '',
            CAREER_SALARY: !salary ? 'โปรดเลือกรายได้ต่อเดือน' : '',
            // `${occupation === 'เจ้าของกิจการ' ? 'กรอกเบอร์ติดต่อกิจการ/ร้านค้า' : 'กรอกเบอร์ที่ทำงาน'} `
            CAREER_COMPANYPHONENUMBER: !companyPhoneNumber ? `โปรดกรอกเบอร์${occupation === 'เจ้าของกิจการ' ? 'ติดต่อกิจการ/ร้านค้า' : 'ที่ทำงาน'}` : '',
            CAREER_PROVEOWN: _.isEmpty(proveOwnBusinessImageFlag) ? 'โปรดเลือกเอกสารกิจการ' : '',
            CAREER_PROVEOWNIMG: _.isEmpty(proveOwnBusinessImage) ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_SALESCHANNELS: !salesChannels ? 'โปรดเลือกช่องทางการขาย' : '',
            CAREER_SALESIMG: (typeProd === 'REFINANCE' && salesChannels === 'OFFLINE' && _.isEmpty(salesChannelsImage)) ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_LINK: (typeProd === 'REFINANCE' && salesChannels === 'ONLINE' && !link) ? 'โปรดกรอกลิงก์ URL หรือเว็บไซต์ ของกิจการ / ร้านค้า / ช่องทางที่มีการขาย' : '',
            CAREER_STATEMENT: _.isEmpty(statement) ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
            CAREER_PASSWORD: !password ? 'โปรดกรอกรหัสเข้าไฟล์' : '',
          }
          setErrorMessage({ ...initStateErrorMessage, ...msg })
          return false
        } else {
          submitData('NEXT')
          amplitudeTrack('3_Job_N_next')
        }
      }
    } else if (type === 'นักเรียน/นักศึกษา') {
      if (!college || !faculty || !branch || !degree || !year || !grade || !salary ||
        !chanelSalary || !statementType || (_.isEmpty(statement) && statementType !== 'NO') || studentImages?.length === 0 || transcriptOrTermFees?.length === 0 ||
        (statementType === '3MONTH' && !password)) {
        const msg: any = {
          CAREER_COLLEGE: !college ? 'โปรดกรอกมหาวิทยาลัย' : '',
          CAREER_FACULTY: !faculty ? 'โปรดกรอกคณะ' : '',
          CAREER_BRANCH: !branch ? 'โปรดกรอกสาขาวิชา' : '',
          CAREER_DEGREE: !degree ? 'โปรดเลือกระดับการศึกษา' : '',
          CAREER_YEAR: !year ? 'โปรดกรอกปี พ.ศ. ที่เข้าเรียน' : year.length !== 4 ? 'ปีการศึกษาที่เข้าเรียนไม่ถูกต้อง' : "",
          CAREER_GRADE: !grade ? 'โปรดเลือกชั้นปีที่' : '',
          CAREER_SALARY: !salary ? 'โปรดเลือกรายได้เฉลี่ยต่อเดือน' : '',
          CAREER_CHANELSALARY: !chanelSalary ? 'โปรดเลือกแหล่งที่มารายได้' : '',
          CAREER_STATEMENTYPE: !statementType ? 'โปรดเลือกเอกสารรายได้' : '',
          CAREER_STATEMENT: statement?.length === 0 && statementType !== 'NO' || _.isEmpty(statement) && statementType !== 'NO' ? 'โปรดอัพโหลดไฟล์เอกสาร' : '',
          CAREER_STUDENTIMG: studentImages?.length === 0 ? 'โปรดอัพโหลดรูปบัตรนักศึกษา' : '',
          CAREER_TRANSCRIPT: transcriptOrTermFees?.length === 0 ? 'โปรดอัพโหลดรูประเบียนการศึกษา หรือใบเสร็จค่าเทอม ' : '',
          CAREER_PASSWORD: (statementType === '3MONTH' && password === '') ? 'โปรดกรอกรหัสเข้าไฟล์' : '',
        }
        setErrorMessage({ ...initStateErrorMessage, ...msg })
        return false
      } else {
        submitData('NEXT')
        amplitudeTrack('3_Job_N_next')
      }
    }
  }

  const onClickBack = async () => {
    amplitudeTrack('3_Job_B_back')
    submitData('BACK')
  }

  return (
    <Styles.Container>
      <Styles.MarginRow>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          row
          value={type}
          onChange={(e: any) => {
            setType(e.target.value)
            clearData()
            amplitudeTrack('3_Job_P_job')
          }}
        >
          <Col className='pt-2' xs={6} md={6}>
            <FormControlLabel
              value="บุคคลทั่วไป"
              control={<Radio
                sx={(theme) => ({
                  '& .MuiSvgIcon-root': {
                    [theme.breakpoints.down('xl')]: {
                      fontSize: 24,
                    },
                    [theme.breakpoints.down('lg')]: {
                      fontSize: 24,
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: 30,
                    }
                  },
                })}
              />}
              label="บุคคลทั่วไป"
            />
          </Col>
          <Col className='pt-2' xs={6} md={6}>
            <FormControlLabel
              value="นักเรียน/นักศึกษา"
              control={<Radio
                sx={(theme) => ({
                  '& .MuiSvgIcon-root': {
                    [theme.breakpoints.down('xl')]: {
                      fontSize: 24,
                    },
                    [theme.breakpoints.down('lg')]: {
                      fontSize: 24,
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: 30,
                    }
                  },
                })}
              />}
              label="นักเรียน/นักศึกษา"
            />
          </Col>
        </RadioGroup>
      </Styles.MarginRow>

      <Styles.MarginRow>
        {type === "บุคคลทั่วไป" && (
          <Col className='pt-2' xs={12} md={6}>
            <AutocompleteSelect
              options={occupationData || []}
              value={_.find(occupationData, (val: any) => val.value === occupation) || null}
              placeholder={"เลือกลักษณะอาชีพ"}
              getOptionLabel={(option: any) => option.label}
              labelId="contracttype"
              onChange={(event, value, reason) => {
                if (value) {
                  setOccupation(value.value)
                  clearDataPersonal()
                  setErrorMessage(initStateErrorMessage)
                  amplitudeTrack('3_Job_P_occupation')
                } else if (reason === 'clear') {
                  setOccupation('')
                  clearData()
                }
              }}
              heading="ลักษณะอาชีพ"
              required
              helperText={errorMessage.CAREER_OCCUPATION}
            />
          </Col>
        )}

        {(type === "บุคคลทั่วไป" && occupation) && (
          <Col className='pt-2' xs={12} md={6}>
            {occupation && <AutocompleteSelect
              options={businessData ? businessData?.filter((val: any) => val.jobOccupation === occupation) : null}
              value={_.find(businessData, (val: any) => val.jobBusiness === business) || null}
              groupBy={(option: any) => option.jobBusinessParrent}
              placeholder={`เลือก${careerVal(occupation)}`}
              getOptionLabel={(option: any) => option.jobBusiness}
              labelId="contracttype"
              onChange={(event, value, reason) => {
                if (value) {
                  setBusiness(value.jobBusiness)
                  setErrorMessage({ ...errorMessage, CAREER_BUSINESS: '' })
                  amplitudeTrack('3_Job_P_business')
                } else if (reason === 'clear') {
                  setBusiness('')
                }
              }}
              heading={careerVal(occupation)}
              required
              helperText={errorMessage.CAREER_BUSINESS}
            />}
          </Col>
        )}
      </Styles.MarginRow>

      {(type === "บุคคลทั่วไป" && occupation) && (
        <>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={6}>
              <InputTextField
                placeholder={`กรอก${careerOcc(occupation)}`}
                value={companyName}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_COMPANYNAME: '' })
                  setCompanyName(e.target.value)
                }}
                required
                heading={careerOcc(occupation)}
                helperText={errorMessage.CAREER_COMPANYNAME}
                onBlur={() => {
                  if (companyName !== '') {
                    amplitudeTrack('3_Job_P_companyname')
                  }
                }}
              />
            </Col>
            <Col className='pt-2' xs={12} md={6}>
              <InputTextField
                placeholder={`กรอก${careerLocation(occupation)}`}
                value={companyLocation}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_COMPANYLOCATION: '' })
                  setCompanyLocation(e.target.value)
                }}
                required
                heading={careerLocation(occupation)}
                helperText={errorMessage.CAREER_COMPANYLOCATION}
                onBlur={() => {
                  if (companyLocation !== '') {
                    amplitudeTrack('3_Job_P_companylocation')
                  }
                }}
              />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={3}>
              <InputTextField
                placeholder={'กรอกตำแหน่งงาน'}
                value={position}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_POSITION: '' })
                  setPosition(e.target.value)
                }}
                required
                heading="ตำแหน่งงาน"
                helperText={errorMessage.CAREER_POSITION}
                onBlur={() => {
                  if (position !== '') {
                    amplitudeTrack('3_Job_P_position')
                  }
                }}
              />
            </Col>
            <Col className='pt-2' xs={12} md={3}>
              <AutocompleteSelect
                options={optionsExperience || []}
                value={_.find(optionsExperience, (val: any) => val.value === experience) || null}
                placeholder={'เลือกอายุงาน'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setExperience(value.value)
                    setErrorMessage({ ...errorMessage, CAREER_EXPERIENCE: '' })
                    amplitudeTrack('3_Job_P_experience')
                  } else if (reason === 'clear') {
                    setExperience('')
                  }
                }}
                required
                heading="อายุงาน"
                helperText={errorMessage.CAREER_EXPERIENCE}
              />
            </Col>
            <Col className='pt-2' xs={12} md={3}>
              <AutocompleteSelect
                options={registerSalary || []}
                value={_.find(registerSalary, (val: any) => val.value === salary) || null}
                placeholder={'เลือกรายได้ต่อเดือน'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setSalary(value.value)
                    setErrorMessage({ ...errorMessage, CAREER_SALARY: '' })
                    amplitudeTrack('3_Job_P_salary')
                  } else if (reason === 'clear') {
                    setSalary('')
                  }
                }}
                required
                heading="รายได้ต่อเดือน"
                helperText={errorMessage.CAREER_SALARY}
              />
            </Col>
            <Col className='pt-2' xs={12} md={3}>
              <InputTextField
                placeholder={`${occupation === 'เจ้าของกิจการ' ? 'กรอกเบอร์ติดต่อกิจการ/ร้านค้า' : 'กรอกเบอร์ที่ทำงาน'} `}
                value={companyPhoneNumber}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_COMPANYPHONENUMBER: '' })
                  setCompanyPhoneNumber(e.target.value)
                }}
                required
                type='number'
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                heading={`${occupation === 'เจ้าของกิจการ' ? 'เบอร์ติดต่อกิจการ/ร้านค้า' : 'เบอร์ที่ทำงาน'} `}
                helperText={errorMessage.CAREER_COMPANYPHONENUMBER}
                onBlur={() => {
                  if (position !== '') {
                    amplitudeTrack('3_Job_P_companytel')
                  }
                }}
              />
            </Col>
          </Styles.MarginRow>

          {(occupation === CAREER.GOVERMENT || occupation === CAREER.PERMANENT) && (
            <>
              <Styles.MarginRow>
                <Col className='pt-2' xs={12} md={9}>
                  <div><span>เอกสารรายได้</span><span style={{ color: colors.danger }}>*</span></div>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row
                    value={statementType}
                    onChange={(e: any) => {
                      setMonths(e.target.value === '3MONTH' ? `${prev3Month}-${month}` : '')
                      setStatementType(e.target.value)
                      setErrorMessage({ ...errorMessage, CAREER_STATEMENTYPE: '', CAREER_PASSWORD: '' })
                      if (e.target.value === '3MONTH') {
                        amplitudeTrack('3_Job_P_statement')
                      } else if (e.target.value === 'CURRENT') {
                        amplitudeTrack('3_Job_P_current')
                      }
                    }}
                  >
                    <Col className='pt-2' xs={12} md={6}>
                      <FormControlLabel
                        value="CURRENT"
                        control={<Radio
                          sx={(theme) => ({
                            '& .MuiSvgIcon-root': {
                              [theme.breakpoints.down('xl')]: {
                                fontSize: 24,
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: 24,
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: 30,
                              }
                            },
                          })}
                        />}
                        label="สลิปเงินเดือนล่าสุด"
                      />
                    </Col>
                    <Col className='pt-2' xs={12} md={6}>
                      <FormControlLabel
                        value={`3MONTH`}
                        control={<Radio
                          sx={(theme) => ({
                            '& .MuiSvgIcon-root': {
                              [theme.breakpoints.down('xl')]: {
                                fontSize: 24,
                              },
                              [theme.breakpoints.down('lg')]: {
                                fontSize: 24,
                              },
                              [theme.breakpoints.down('md')]: {
                                fontSize: 30,
                              }
                            },
                          })}
                        />}
                        label={`Statement ${prev3Month}-${month}`}
                      />
                    </Col>
                  </RadioGroup>
                  {errorMessage.CAREER_STATEMENTYPE !== '' &&
                    <div>
                      <HelperText label={errorMessage.CAREER_STATEMENTYPE} />
                    </div>
                  }
                </Col>
                {statementType && (
                  <Col className='pt-2' xs={12} md={3}>
                    <InputTextField
                      placeholder={'กรอกรหัสเข้าไฟล์'}
                      value={password}
                      onchange={(e: any) => {
                        setErrorMessage({ ...errorMessage, CAREER_PASSWORD: '' })
                        setPassword(e.target.value)
                        amplitudeTrack('3_Job_P_password')
                      }}
                      required={statementType === '3MONTH' ? true : false}
                      heading="รหัสเข้าไฟล์"
                      helperText={errorMessage.CAREER_PASSWORD}
                    />
                  </Col>
                )}
              </Styles.MarginRow>

              {statementType && (
                <Styles.MarginRow>
                  <Col className='pt-2' xs={12} md={6}>
                    <div><span>แนบไฟล์เอกสาร</span><span style={{ color: colors.danger }}>*</span></div>
                    <UploadImage
                      onUpload={(event: any) => { onUpload(event, 'statement') }}
                      helperText={errorMessage.CAREER_STATEMENT}
                      loading={loadingImg.statement}
                    />
                    <div className="pt-3">
                      {statement && (
                        <>
                          {statement?.map((val: any, index: number) => {
                            return (
                              <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'statement') }} />
                            )
                          })}
                        </>
                      )}
                    </div>
                  </Col>
                </Styles.MarginRow>
              )}
              {occupation === CAREER.GOVERMENT &&
                <Styles.MarginRow>
                  <Col className='pt-2' xs={12} md={6}>
                    <div><span>แนบภาพบัตรประจำตัวพนักงานราชการ</span><span style={{ color: colors.danger }}>*</span></div>
                    <UploadImage
                      onUpload={(event: any) => { onUpload(event, 'governmentOfficial') }}
                      helperText={errorMessage.CAREER_GOVERNMENT}
                      loading={loadingImg.governmentOfficial}
                    />
                    <div className="pt-3">
                      {governmentOfficial && (
                        <>
                          {governmentOfficial?.map((val: any, index: number) => {
                            return (
                              <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'governmentOfficial') }
                              } />
                            )
                          })}
                        </>
                      )}
                    </div>

                  </Col>
                </Styles.MarginRow>
              }
            </>
          )}


          {(occupation === CAREER.OWNER || occupation === CAREER.TEMPORARY) &&
            <>
              {occupation === CAREER.OWNER && (
                <>
                  <Styles.MarginRow>
                    <Col className='pt-2' xs={12} md={6}>
                      <div><span>เอกสารกิจการ</span><span style={{ color: colors.danger }}>*</span>
                        {/* {typeProd === 'REFINANCE' && (<span style={{ color: colors.danger }}>(กรณีใบจดทะเบียนหรือเอกสารแสดงความเป็นเจ้าของกิจการเป็นบุคคลในเครือญาติ โปรดใส่ชื่อบุคคลนั้นลงผู้ติดต่อ)</span>)} */}
                      </div>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        row
                        value={proveOwnBusinessImageFlag}
                        onChange={(e: any) => {
                          setErrorMessage({ ...errorMessage, CAREER_PROVEOWN: '' })
                          setProveOwnBusinessImageFlag(e.target.value)
                        }}
                      >
                        <Col className='pt-2' xs={12} md={6}>
                          <FormControlLabel
                            value={true}
                            control={<Radio
                              sx={(theme) => ({
                                '& .MuiSvgIcon-root': {
                                  [theme.breakpoints.down('xl')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('lg')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('md')]: {
                                    fontSize: 30,
                                  }
                                },
                              })}
                            />}
                            label="กิจการจดทะเบียน"
                          />
                        </Col>
                        <Col className='pt-2' xs={12} md={6}>
                          <FormControlLabel
                            value={false}
                            control={<Radio
                              sx={(theme) => ({
                                '& .MuiSvgIcon-root': {
                                  [theme.breakpoints.down('xl')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('lg')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('md')]: {
                                    fontSize: 30,
                                  }
                                },
                              })}
                            />}
                            label="กิจการไม่จดทะเบียน"
                          />
                        </Col>
                      </RadioGroup>
                      {errorMessage.CAREER_PROVEOWN !== '' &&
                        <div>
                          <HelperText label={errorMessage.CAREER_PROVEOWN} />
                        </div>
                      }
                    </Col>
                    <Col className='pt-2' xs={12} md={6}>
                      <div><span>ช่องทางการขาย</span><span style={{ color: colors.danger }}>*</span></div>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        row
                        value={salesChannels}
                        onChange={(e: any) => {
                          setErrorMessage({ ...errorMessage, CAREER_SALESCHANNELS: '' })
                          setSalesChannels(e.target.value)
                          if (typeProd === 'REFINANCE') {
                            setLink('')
                            setSalesChannelsImage([])
                          }
                        }}
                      >
                        <Col className='pt-2' xs={12} md={6}>
                          <FormControlLabel
                            value="ONLINE"
                            control={<Radio
                              sx={(theme) => ({
                                '& .MuiSvgIcon-root': {
                                  [theme.breakpoints.down('xl')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('lg')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('md')]: {
                                    fontSize: 30,
                                  }
                                },
                              })}
                            />}
                            label="ช่องทางออนไลน์"
                          />
                        </Col>
                        <Col className='pt-2' xs={12} md={6}>
                          <FormControlLabel
                            value="OFFLINE"
                            control={<Radio
                              sx={(theme) => ({
                                '& .MuiSvgIcon-root': {
                                  [theme.breakpoints.down('xl')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('lg')]: {
                                    fontSize: 24,
                                  },
                                  [theme.breakpoints.down('md')]: {
                                    fontSize: 30,
                                  }
                                },
                              })}
                            />}
                            label="ช่องทางหน้าร้าน"
                          />
                        </Col>
                      </RadioGroup>
                      {errorMessage.CAREER_SALESCHANNELS !== '' &&
                        <div>
                          <HelperText label={errorMessage.CAREER_SALESCHANNELS} />
                        </div>
                      }
                    </Col>
                  </Styles.MarginRow>

                  <Styles.MarginRow>
                    {(proveOwnBusinessImageFlag !== '' && salesChannels !== '') && (
                      <Col className='pt-2' xs={12} md={6}>
                        <div><span>{typeProd === 'REFINANCE' ? proveOwnBusinessImageFlag === 'true' ? 'แนบใบจดทะเบียนการค้า / ใบอนุญาติ / สัญญาเช่า หรือ หลักฐานใดที่แสดงความเป็นเจ้าของ' : 'แนบภาพหน้าร้าน หรือ ภาพกิจการ' : 'แนบภาพหน้าร้าน หรือ ภาพกิจการ'}</span><span style={{ color: colors.danger }}>*</span></div>
                        <UploadImage
                          onUpload={(event: any) => { onUpload(event, 'proveOwnBusinessImage') }}
                          helperText={errorMessage.CAREER_PROVEOWNIMG}
                          loading={loadingImg.proveOwnBusinessImage}
                        />
                        <div className="pt-3">
                          {proveOwnBusinessImage && (
                            <>
                              {proveOwnBusinessImage?.map((val: any, index: number) => {
                                return (
                                  <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'proveOwnBusinessImage') }} />
                                )
                              })}
                            </>
                          )}
                        </div>
                      </Col>
                    )}

                    {(salesChannels !== '' && proveOwnBusinessImageFlag !== '') && (
                      <>
                        {proveOwnBusinessImageFlag === '' && (<Col className='pt-2' xs={12} md={6}><></></Col>)}
                        <Col className='pt-2' xs={12} md={6}>
                          {(salesChannels === 'ONLINE' ||
                            ((typeProd !== 'REFINANCE' && salesChannels === 'ONLINE')) ||
                            ((typeProd !== 'REFINANCE' && salesChannels !== 'ONLINE'))) && (
                              <InputTextField
                                placeholder={`ลิงก์ร้านค้าหรือช่องทางการขาย ${typeProd !== 'REFINANCE' ? '(ถ้ามี)' : ''}`}
                                value={link}
                                required={typeProd !== 'REFINANCE' ? false : true}
                                heading={`ลิงก์เว็บไซต์ของกิจการ ${typeProd !== 'REFINANCE' ? '(ถ้ามี)' : ''}`}
                                onchange={(e: any) => {
                                  setErrorMessage({ ...errorMessage, CAREER_LINK: '' })
                                  setLink(e.target.value)
                                }}
                                helperText={errorMessage.CAREER_LINK}
                              />
                            ) || (
                              <>
                                <div><span>{(typeProd == 'REFINANCE' && salesChannels === 'OFFLINE' && proveOwnBusinessImageFlag === 'false') ? 'แนบภาพสินค้า หรือ ภาพการบริการ' : 'แนบภาพหน้าร้าน หรือ ภาพกิจการ'}</span><span style={{ color: colors.danger }}>*</span></div>
                                <UploadImage
                                  onUpload={(event: any) => { onUpload(event, 'salesChannelsImage') }}
                                  helperText={errorMessage.CAREER_SALESIMG}
                                  loading={loadingImg.salesChannelsImage}
                                />
                                <div className="pt-3">
                                  {salesChannelsImage && (
                                    <>
                                      {salesChannelsImage?.map((val: any, index: number) => {
                                        return (
                                          <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'salesChannelsImage') }} />
                                        )
                                      })}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                        </Col>
                      </>
                    )}
                  </Styles.MarginRow>
                </>
              )}

              <Styles.MarginRow>
                <Col className='pt-2' xs={12} md={6}>
                  <div><span>{`เอกสารรายได้ Statement ${prev3Month} - ${month}`}</span><span style={{ color: colors.danger }}>*</span></div>
                  <UploadImage
                    onUpload={(event: any) => { onUpload(event, 'statement') }}
                    helperText={errorMessage.CAREER_STATEMENT}
                    loading={loadingImg.statement}
                  />
                  <div className="pt-3">
                    {statement && (
                      <>
                        {statement?.map((val: any, index: number) => {
                          return (
                            <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'statement') }} />
                          )
                        })}
                      </>
                    )}
                  </div>

                </Col>
                <Col xs={12} md={3} />
                <Col className='pt-2' xs={12} md={3}>
                  <InputTextField
                    placeholder={'กรอกรหัสเข้าไฟล์'}
                    value={password}
                    required
                    heading="รหัสเข้าไฟล์"
                    onchange={(e: any) => {
                      setErrorMessage({ ...errorMessage, CAREER_PASSWORD: '' })
                      setPassword(e.target.value)
                    }}
                    helperText={errorMessage.CAREER_PASSWORD}
                  />
                </Col>
              </Styles.MarginRow>

              {occupation === CAREER.TEMPORARY &&
                <Styles.MarginRow>
                  <Col className='pt-2' xs={12} md={6}>
                    <div><span>{business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' ? 'แนบภาพหน้าแอปพลิเคชั่นไรเดอร์ (หน้าชื่อ-นามสกุลลูกค้า) และหน้าสรุปยอดการทำงานล่าสุด' : 'แนบภาพการทำงาน 2-3 ภาพ หรือเอกสารหลักฐานใดที่ยืนยันการทำงาน'}</span><span style={{ color: colors.danger }}>*</span></div>
                    <UploadImage
                      onUpload={(event: any) => { onUpload(event, business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' ? 'riderAppication' : 'proveWork') }}
                      helperText={errorMessage.CAREER_PROVEWORK || errorMessage.CAREER_RIDER}
                      loading={loadingImg.riderAppication || loadingImg.proveWork}
                    />
                    <div className="pt-3">
                      <>
                        {
                          _.map(business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' ? riderAppication : proveWork, (val: any, index: number) => {
                            return (
                              <UploadImageList preview={val} onDelete={() => { onDelete(val, index, business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' ? 'riderAppication' : 'proveWork') }} />
                            )
                          })
                        }
                      </>
                    </div>

                  </Col>
                </Styles.MarginRow>
              }
            </>
          }
        </>
      )}

      {type === "นักเรียน/นักศึกษา" && (
        <div>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={4}>
              <InputTextField
                placeholder={'กรอกมหาวิทยาลัย'}
                value={college}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_COLLEGE: '' })
                  setCollege(e.target.value)
                }}
                required
                heading="มหาวิทยาลัย"
                helperText={errorMessage.CAREER_COLLEGE}
                onBlur={() => {
                  if (college !== '') {
                    amplitudeTrack('3_Job_P_college')
                  }
                }}
              />
            </Col>
            <Col className='pt-2' xs={12} md={4}>
              <InputTextField
                placeholder={'กรอกคณะ'}
                value={faculty}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_FACULTY: '' })
                  setFaculty(e.target.value)
                }}
                required
                heading="คณะ"
                helperText={errorMessage.CAREER_FACULTY}
                onBlur={() => {
                  if (faculty !== '') {
                    amplitudeTrack('3_Job_P_faculty')
                  }
                }}
              />
            </Col>
            <Col className='pt-2' xs={12} md={4}>
              <InputTextField
                placeholder={'กรอกสาขาวิชา'}
                value={branch}
                onchange={(e: any) => {
                  setErrorMessage({ ...errorMessage, CAREER_BRANCH: '' })
                  setBranch(e.target.value)
                }}
                required
                heading="สาขาวิชา"
                helperText={errorMessage.CAREER_BRANCH}
                onBlur={() => {
                  if (branch !== '') {
                    amplitudeTrack('3_Job_P_major')
                  }
                }}
              />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={4}>
              <AutocompleteSelect
                options={optionDegree || []}
                value={_.find(optionDegree, (val: any) => val.value === degree) || null}
                placeholder={'เลือกระดับการศึกษา'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, CAREER_DEGREE: '' })
                    amplitudeTrack('3_Job_P_grade')
                    setDegree(value.value)
                  } else if (reason === 'clear') {
                    setDegree('')
                  }
                }}
                required
                heading="ระดับการศึกษา"
                helperText={errorMessage.CAREER_DEGREE}
              />
            </Col>
            <Col className='pt-2' xs={12} md={4}>
              <AutocompleteSelect
                options={optionRegisterCollge || []}
                value={_.find(optionRegisterCollge, (val: any) => val.value === grade) || null}
                placeholder={'เลือกชั้นปีที่'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setGrade(value.value)
                    amplitudeTrack('3_Job_P_year')
                    setErrorMessage({ ...errorMessage, CAREER_GRADE: '' })

                  } else if (reason === 'clear') {
                    setGrade('')
                  }
                }}
                required
                helperText={errorMessage.CAREER_GRADE}
                heading='ชั้นปีที่'
              />
            </Col>
            <Col className='pt-2' xs={12} md={4}>
              <InputTextField
                placeholder={'กรอกปี พ.ศ. ที่เข้าเรียน'}
                value={year}
                onchange={(e: any) => {
                  if (e.target.value.length <= 4) {
                    setYear(e.target.value)
                    setErrorMessage({ ...errorMessage, CAREER_YEAR: '' })
                  }
                }}
                required
                heading="ปี พ.ศ. ที่เข้าเรียน"
                type='number'
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                helperText={errorMessage.CAREER_YEAR}
                onBlur={() => {
                  if (year !== '') {
                    amplitudeTrack('3_Job_P_year')
                  }
                }}
              />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={6}>
              <AutocompleteSelect
                options={registerSalary || []}
                value={_.find(registerSalary, (val: any) => val.value === salary) || null}
                placeholder={'เลือกรายได้เฉลี่ยต่อเดือน'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setSalary(value.value)
                    setErrorMessage({ ...errorMessage, CAREER_SALARY: '' })
                  } else if (reason === 'clear') {
                    setSalary('')
                  }
                }}
                required
                helperText={errorMessage.CAREER_SALARY}
                heading="รายได้เฉลี่ยต่อเดือน"
              />
            </Col>
            <Col className='pt-2' xs={12} md={6}>
              <AutocompleteSelect
                options={optionsSalaryStudent || []}
                value={_.find(optionsSalaryStudent, (val: any) => val.value === chanelSalary) || null}
                placeholder={'เลือกแหล่งที่มารายได้'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setChanelSalary(value.value)
                    setErrorMessage({ ...errorMessage, CAREER_CHANELSALARY: '' })
                  } else if (reason === 'clear') {
                    setChanelSalary('')
                  }
                }}
                required
                helperText={errorMessage.CAREER_CHANELSALARY}
                heading='แหล่งที่มารายได้'
              />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={6}>
              <div><span>บัตรนักศึกษา</span><span style={{ color: colors.danger }}>*</span></div>
              <UploadImage
                onUpload={(event: any) => { onUpload(event, 'studentImages') }}
                helperText={errorMessage.CAREER_STUDENTIMG}
                loading={loadingImg.studentImages}
              />
              <div className="pt-3">
                {studentImages && (
                  <>
                    {studentImages?.map((val: any, index: number) => {
                      return (
                        <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'studentImages') }} />
                      )
                    })}
                  </>
                )}
              </div>
            </Col>
            <Col className='pt-2' xs={12} md={6}>
              <div><span>แนบระเบียนการศึกษา หรือใบเสร็จค่าเทอม</span><span style={{ color: colors.danger }}>*</span></div>
              <UploadImage
                onUpload={(event: any) => { onUpload(event, 'transcriptOrTermFees') }}
                helperText={errorMessage.CAREER_TRANSCRIPT}
                loading={loadingImg.transcriptOrTermFees}
              />
              <div className="pt-3">
                {transcriptOrTermFees && (
                  <>
                    {transcriptOrTermFees?.map((val: any, index: number) => {
                      return (
                        <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'transcriptOrTermFees') }} />
                      )
                    })}
                  </>
                )}
              </div>
            </Col>

          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col className='pt-2' xs={12} md={9}>
              <div><span>เอกสารรายได้</span><span style={{ color: colors.danger }}>*</span></div>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={statementType}
                onChange={(e: any) => {
                  setMonths(e.target.value === '3MONTH' ? `${prev3Month}-${month}` : '')
                  setStatementType(e.target.value)
                  setErrorMessage({ ...errorMessage, CAREER_STATEMENTYPE: '', CAREER_PASSWORD: '' })
                  if (e.target.value === '3MONTH') {
                    amplitudeTrack('3_Job_P_statementstudent')
                  } else if (e.target.value === 'CURRENT') {
                    amplitudeTrack('3_Job_P_currentstudent')
                  } else if (e.target.value === 'NO') {
                    amplitudeTrack('3_Job_P_nostudent')
                  }
                }}
              >
                <Col className='pt-2' xs={12} md={4}>
                  <FormControlLabel
                    value="3MONTH"
                    control={<Radio
                      sx={(theme) => ({
                        '& .MuiSvgIcon-root': {
                          [theme.breakpoints.down('xl')]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down('lg')]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down('md')]: {
                            fontSize: 30,
                          }
                        },
                      })}
                    />}
                    label={`Statement ${prev3Month}-${month}`}
                  />
                </Col>
                <Col className='pt-2' xs={12} md={4}>
                  <FormControlLabel
                    value="CURRENT"
                    control={<Radio
                      sx={(theme) => ({
                        '& .MuiSvgIcon-root': {
                          [theme.breakpoints.down('xl')]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down('lg')]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down('md')]: {
                            fontSize: 30,
                          }
                        },
                      })}
                    />}
                    label="สลิปเงินเดือนล่าสุด"
                  />
                </Col>
                {Number(props.downPayment) <= 15000 && props.data.type === 'INSTALLMENT' && (
                  <Col className='pt-2' xs={12} md={4}>
                    <FormControlLabel
                      value="NO"
                      control={<Radio
                        sx={(theme) => ({
                          '& .MuiSvgIcon-root': {
                            [theme.breakpoints.down('xl')]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: 30,
                            }
                          },
                        })}
                      />}
                      label="ไม่มี"
                    />
                  </Col>
                )}
              </RadioGroup>
              {errorMessage.CAREER_STATEMENTYPE !== '' &&
                <div>
                  <HelperText label={errorMessage.CAREER_STATEMENTYPE} />
                </div>
              }
            </Col>
            {statementType && statementType !== 'NO' && (
              <Col className='pt-2' xs={12} md={3}>
                <InputTextField
                  placeholder={'กรอกรหัสเข้าไฟล์'}
                  value={password}
                  onchange={(e: any) => setPassword(e.target.value)}
                  required={statementType === '3MONTH' ? true : false}
                  heading="รหัสเข้าไฟล์"
                  helperText={errorMessage.CAREER_PASSWORD}
                  onBlur={() => {
                    if (password !== '') {
                      amplitudeTrack('3_Job_P_passwordstudent')
                    }
                  }
                  }
                />
              </Col>
            )}

          </Styles.MarginRow>
          {
            statementType && statementType !== 'NO' && (
              <Styles.MarginRow>
                <Col className='pt-2' xs={12} md={6}>
                  <div><span>แนบไฟล์เอกสาร</span><span style={{ color: colors.danger }}>*</span></div>
                  <UploadImage
                    onUpload={(event: any) => { onUpload(event, 'statement') }}
                    helperText={errorMessage.CAREER_STATEMENT}
                    loading={loadingImg.statement}
                  />
                  <div className="pt-3">
                    {statement && (
                      <>
                        {statement?.map((val: any, index: number) => {
                          return (
                            <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'statement') }} />
                          )
                        })}
                      </>
                    )}
                  </div>
                </Col>
              </Styles.MarginRow>
            )
          }
        </div >
      )}


      <Styles.MarginRow>
        <Col className='pt-2'>
          <ButtonCustom
            variant={'outline'}
            btnStyle={{ width: '100%' }}
            textButton={"ย้อนกลับ"}
            onClick={() => {
              onClickBack()
            }}
          />
        </Col>
        <Col className='pt-2'>
          <ButtonCustom
            btnStyle={{ width: '100%' }}
            textButton={"ยืนยัน"}
            onClick={() => {
              onSubmit()
            }}
          />
        </Col>
      </Styles.MarginRow>
    </Styles.Container >
  );
}
