import { Box, Button } from '@mui/material'
import React from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

/** STYLE */
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'
import { onImgError } from '../../constants/images'

type Image = {
  image: string
  fileName?: string
  objectFit?: string
  height?: string;
  disabled?: boolean | false
}

const ToolsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: 0,
  transform: 'translateX(-50%)',
  zIndex: 1,
  backgroundColor: colors.white,
  borderRadius: 100,
  padding: '6px 16px',
  [theme.breakpoints.down('lg')]: {
    padding: '4px 10px'
  }
}))

const Tools = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 'unset',
  width: 30,
  height: 30,
  backgroundColor: colors.extraLightGray,
  borderRadius: 100,
  color: colors.textPrimary,
  svg: {
    fontSize: 18
  },
  '&.btn-reset-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&:hover': {
    backgroundColor: colors.lightGray
  },
  [theme.breakpoints.down('lg')]: {
    width: 24,
    height: 24
  }
}))

export default function PreviewImage(props: Image) {

  const renderImage = (img: string) => {
    if (img !== '') {
      return img
    }
  }

  return (
    <Box className="position-relative h-100 overflow-hidden" style={{ border: `1px solid ${colors.lightGray}` }}>
      <TransformWrapper centerOnInit disabled={props.disabled}
        alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
        centerZoomedOut={true}>
        {({ zoomIn, zoomOut, resetTransform }: { zoomIn: any, zoomOut: any, resetTransform: any, }) => (
          <React.Fragment>
            {!props.disabled && (
              <>
                <Tools onClick={() => resetTransform()} className="btn-reset-position">
                  {'รีเซ็ต'}
                </Tools>
                <ToolsBox className="d-flex align-items-center justify-content-center mb-2">
                  <Tools onClick={() => zoomOut()} className="mr-1 mr-xl-2">
                    <RemoveIcon />
                  </Tools>
                  <Tools onClick={() => zoomIn()} className="ml-1 ml-xl-2">
                    <AddIcon />
                  </Tools>
                </ToolsBox>
              </>
            )}
            <TransformComponent wrapperClass="w-100 h-100" contentClass="w-100" contentStyle={{ cursor: 'move', height: 400}}>
              <img style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: "contain",
              }}
                src={renderImage(props.image)}
                alt={props.fileName}
                className={`w-auto h-100 m-auto ${props.objectFit && `object-fit-${props.objectFit}`}`}
                onError={onImgError} />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </Box>
  )
}
