import BaseAPI from "../api";

const path = "admin/authen/branchRegis";

interface branch {
  branchName: string;
}

interface region {
  type: string;
}

export default class GoogleMapApi extends BaseAPI {
  static getLocation(payload: branch): Promise<any> {
    return this.api.post(`${path}/location`, payload).then((res) => res);
  }
  static getRegion(payload: region): Promise<any> {
    return this.api.post(`${path}/options`, payload).then((res) => res);
  }
}
