import { defaultMenu } from "../constants/menu";
import Branch from "../pages/branch/branch";
import Register from "../pages/register/register";
import { routeName } from "./routers-name";

const routes = [
  {
    key: defaultMenu.REGISTER.key,
    name: defaultMenu.REGISTER.name,
    textName: defaultMenu.REGISTER.textName,
    component: Register,
    path: routeName.register,
    header: true,
    footer: true
  },
  {
    key: defaultMenu.NEAR_BRANCH.key,
    name: defaultMenu.NEAR_BRANCH.name,
    textName: defaultMenu.NEAR_BRANCH.textName,
    component: Branch,
    path: routeName.nearBranch,
    header: true,
    footer: true
  },
];

export default routes;
