import {
  Check,
  ChevronRight,
  ContentCopy,
  WarningOutlined,
} from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";
import ButtonCustom from "../../../components/button/buttonCustom";
import PreviewImage from "../../../components/image/previewImage";
import { colors } from "../../../constants/colors";
import { imgs } from "../../../constants/images";
import Contact from "../componant/contact";
import { REGISTER_TYPE_TH } from "../default";
import * as Styles from "./layout-complete.style";
import { amplitudeTrack } from "../../../utils/amplitude/amplitude";

interface props {
  profile: any;
  type: string;
  chatcone: any
}

export default function LayoutComplete(props: props) {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const recommendProduct = [
    imgs.iPhoneXAll,
    imgs.iPhoneXSAll,
    imgs.iPhoneXRAll,
    imgs.iPhone11All,
  ];
  const content = `ลงทะเบียน${REGISTER_TYPE_TH[props.type as keyof typeof REGISTER_TYPE_TH]
    }เสร็จสมบูรณ์`;
  const fullname = `คุณ ${props.profile?.firstName} ${props.profile?.lastName}`;
  const copyContent = `${fullname} ${content}`;

  const handleCopy = () => {
    setIsCopy(true);
    navigator.clipboard.writeText(copyContent);
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
    amplitudeTrack('6_Successful_P_copy')
  };

  return (
    <Styles.Container>

      {props.chatcone && (<>
        <>
          <Styles.CenterContainer>
            <CheckCircleIcon
              style={{
                margin: "auto",
                fontSize: "85px",
                color: colors.green,
              }}
            />
            <Typography variant="subtitle1" fontWeight={500}>
              {fullname}
            </Typography>
            <Typography variant="h5" fontWeight={500} mb={3} mt={1}>
              {content}
            </Typography>
            <Styles.ContactBox my={7}>
              <Contact titleName='ติดต่อเจ้าหน้าที่' />
            </Styles.ContactBox>
          </Styles.CenterContainer>
        </>
      </>) || (
          <>
            <WarningOutlined
              style={{
                margin: "auto",
                fontSize: "65px",
                color: colors.warning,
              }}
            />
            <Typography variant="subtitle1" fontWeight={500}>
              {fullname}
            </Typography>
            <Typography variant="h5" fontWeight={500} mb={3} mt={1}>
              {content}
            </Typography>
            <Typography variant="h5" fontWeight={500}>
              กรุณาคัดลอกข้อความนี้ และส่งให้เจ้าหน้าที่ทางแชทของยูเฟรนด์
            </Typography>
            <Styles.Snippet mb={4} mt={2} maxWidth={700} mx={"auto"}>
              <Tooltip title="คัดลอกข้อความนี้" placement="right">
                <IconButton
                  sx={{ position: "absolute", top: 10, right: 10 }}
                  size="small"
                  onClick={handleCopy}
                >
                  {isCopy ? <Check /> : <ContentCopy />}
                </IconButton>
              </Tooltip>
              <Typography variant="h6" fontWeight={500}>
                {fullname}
              </Typography>
              <Typography variant="h6" fontWeight={500} mt={1}>
                {content}
              </Typography>
            </Styles.Snippet>
            <Link href="https://lin.ee/FJBOd2R" target="_blank" onClick={() => { amplitudeTrack('6_Successful_N_sent') }} >
              <img src={imgs.buttonWeb} width={400} />
            </Link>
          </>
        )}
      <Link href="https://www.ufriend.shop/">
        <ButtonCustom
          startIcon={<ChevronRight />}
          textButton="กลับหน้าแรก"
          style={{ marginTop: 3 }}
          onClick={() => { amplitudeTrack('6_Successful_O_home') }}
        />
      </Link>
      <Typography variant="h5" fontWeight={500} mb={3} mt={10}>
        ผลิตภัณฑ์แนะนำ
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="center" gap={2}>
        {recommendProduct.map((item, index) => (
          <PreviewImage image={item} key={index} />
        ))}
      </Box>
      <Link href="https://www.ufriend.shop/product-category/smartphone/">
        <ButtonCustom
          startIcon={<ChevronRight />}
          textButton="ดูเพิ่มเติม"
          style={{ marginTop: 3 }}
          onClick={() => { amplitudeTrack('6_Successful_O_moredetail') }}
        />
      </Link>
    </Styles.Container>
  );
}
